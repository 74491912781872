<template>
  <!-- 注册窗口 -->
    <div id="register" class="modal fade" tabindex="-1" >
        <div class="modal-dialog" >
            <div class="modal-content" style="border-radius: 21px;">
                <div class="modal-body">
<!--                    <button class="close" data-dismiss="modal">-->
<!--                        <span>×</span>-->
<!--                    </button>-->
                </div>
                <div class="modal-title">
                    <h1 class="text-center">{{ t("register.title") }}</h1>
                </div>
                <div class="modal-body">
                    <form class="form-group" action="" >
                        <div class="form-group">
                            <div class="form-group">
                                <input class="form-control" v-model="regInfo.email" type="email" placeholder="例如:123@123.com">
                            </div>
                        </div>
                        <div class="form-group">
                            <input class="form-control"  v-model="regInfo.pass1" type="password" placeholder="密码,至少6位字母或数字">
                        </div>
                        <div class="form-group">
                            <input class="form-control" v-model="regInfo.pass2" type="password" placeholder="再次输入密码,至少6位字母或数字">
                        </div>

                        <div class="text-right">
                            <button class="btn btn-primary" type="button" @click="reg">提交</button>
                            <button class="btn btn-danger" data-dismiss="modal">取消</button>
                        </div>
                        <a href="" data-toggle="modal" data-dismiss="modal" data-target="#login">已有账号？点我登录</a>
                    </form>
                </div>
            </div>
        </div>
    </div>

  <!-- 登录窗口 -->
    <div id="login" class="modal fade" >
        <div class="modal-dialog">
            <div class="modal-content" style="border-radius: 21px;">
                <div class="modal-body">
<!--                    <button class="close" data-dismiss="modal">-->
<!--                        <span>×</span>-->
<!--                    </button>-->
                </div>
                <div class="modal-title">
                    <h1 class="text-center">{{ t("login.title") }}</h1>
                </div>
                <div class="modal-body">
                    <form class="form-group" @submit="onLogin">
                        <div class="form-group">
                            <input class="form-control"  v-model="loginInfo.email" type="text" placeholder="user name">
                        </div>
                        <div class="form-group">
                            <input class="form-control" v-model="loginInfo.pass1"  type="password" placeholder="password">
                        </div>
                        <div class="text-right">
                            <button class="btn btn-primary" style="margin: 10px" >login</button>
                            <button class="btn btn-danger" data-dismiss="modal">cancel</button>
                        </div>
                        <a href="" data-toggle="modal" data-dismiss="modal" @click="showReg">no account,register?</a>
                    </form>
                </div>
            </div>
        </div>
        <div id="login_container"></div>
    </div>
</template>

<script setup >

import {onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {login, regUsr} from "../api/api";
import { getCurrentInstance } from 'vue';
import {useStore} from "vuex";
import {fetchNote, getLocalString, getStackTraceString} from "../utility/common";
import {useI18n} from "vue-i18n";
import {showSucc,showFail} from "./toast";
///todo 这个地方使用bootstrap.bundle.min刚开始几次vite报504
//GET http://127.0.0.1:7088/node_modules/.vite/deps/bootstrap_dist_js_bootstrap__min__js.js?v=80cdff94 net::ERR_ABORTED 504 (Outdated Optimize Dep)
import 'bootstrap/dist/js/bootstrap.bundle.min';

const { t, te,locale } = useI18n();  // 解构调用函数

let store = useStore();

///setting要有默认参数,如果没有,sever端默认解析的时候会报异常
var regInfo =reactive({email:'',pass1:'',pass2:"",settings:store.state.settings})

var loginInfo =reactive({email:'',pass1:''})

const instance = getCurrentInstance();

let logindStyles = ref('')///这样不行,index界面的login 元素访问不了当前的这个变量绑定的css样式表 .logined

function showReg(){
  $('#register').modal({
    backdrop: 'static',
    keyboard: false
  })
}
async function  reg(){
    event.preventDefault(); // 阻止默认提交行为

    console.log("regInfo",JSON.parse(JSON.stringify(regInfo)));
    if (regInfo.pass1.length ==0 || regInfo.pass2.length ==0){
        showFail(t("register.err_mima_kong"),getStackTraceString());
        console.log("pss1,pass2",regInfo.pass1,regInfo.pass2);
        return;
    }
    if(regInfo.pass1 === regInfo.pass2){
        let tmp = await regUsr(JSON.parse(JSON.stringify(regInfo)) )

        console.log("regUsr",tmp.data)

        if(tmp.data.error===0){
            onRegOk();
        }

        showSucc(t(tmp.data.msg));
    }else{
        showFail(t("register.err_mima_buyiyang"),getStackTraceString());
    }
}

function caclReturnUrl(){
  let url = new URL(window.location.href);
  // 解析查询字符串参数
  let params = new URLSearchParams(url.search);
  console.log("params", atob(params.get('ourl')))
  return atob(params.get('ourl'));
  // // 删除特定参数
  // params.delete('ck');
  // params.delete('name');
  // // 构建新的查询字符串
  // let newSearch = params.toString() ? `?${params.toString()}` : '';
  // // 构建新的URL
  // let newUrl = `${url.protocol}//${url.host}${url.pathname}${newSearch}`;

  //window.location.href = newUrl;
}
async function  onLogin(){

    event.preventDefault(); // 阻止默认提交行为

    console.log("loginInfo",JSON.parse(JSON.stringify(loginInfo)));

    if (loginInfo.pass1.length ==0 || loginInfo.email.length ==0) {
      showFail(t("login.wrong_input"));
      return;
    }

    let tmp = await login(JSON.parse(JSON.stringify(loginInfo)) )

    console.log("loginInfo",tmp.data)

    if(tmp.data.error===0){
        let currentDomain = window.location.host;
        store.state.login = true;
        store.state.userName = tmp.data.user;
        store.state.userDBinfo = tmp.data.userDBinfo;
        console.log("set cookie with domain",currentDomain,tmp.data.jwt,store.state.userName);
        ///这个地方用url传递而不是直接保存cookie是为了防止登录和应用服务不是在一个域名下,比如测试环境端口不一样
        ///knowledge: 看这个日志,端口不一样,属于不同domain,set cookie with domain 127.0.0.1:7088 eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMTFAMjIifQ.qxNyLrioEH_1UCBgNo3L1e1QfX9-xvVia5924_jdWew 11@22

        let url = caclReturnUrl();
        window.location.href = url+'?ck='+tmp.data.jwt+'&name='+tmp.data.user;
        console.log("window.location.href",window.location.href)
        showSucc(getLocalString(te,t,tmp.data.msg));
    }
    else{
      showFail(getLocalString(te,t,tmp.data.msg));
    }
}

function getLocalLoginInfo(){
    {
        let token = $cookie.get('token')
        //console.log("getToken:",token);

        if (token === null) {
            store.state.userName  = '';
            store.commit("resetUserInfo");
        }
        else {
            store.state.userName  = $cookie.get('userName')
            //console.log("get cookie userName:",loginedInfo.userName);
        }
    }
}
let timer = null;

onMounted(() => {

//每5s刷新数据
    timer = setInterval(() => {
        getLocalLoginInfo();
    }, 5000);

    const { appContext : { config: { globalProperties } } } = getCurrentInstance()

    console.log("globalProperties:",store.state)

    getLocalLoginInfo();

    locale.value = store.state.settings.lang;

  $('#login').modal({
    backdrop: 'static',
    keyboard: false
  })

})

onBeforeUnmount(() => {
    clearInterval(timer)
    timer = null;
})

function onRegOk(){
    fetchNote();
    // $('#login').modal('hide')
    // $('#register').modal('hide')
}

</script>

<style lang="less" scoped>
@import 'bootstrap/dist/css/bootstrap.min.css';
.logined{
    background: v-bind('logindStyles')
}
</style>
