// 子页面1的入口文件
//import PageOne from '../src/views/login.vue';
import PageOne from '../src/components/login.vue';
import '../src/index.css'
import {createApp, reactive, watch} from 'vue'
import { useI18n } from "vue-i18n";  // 引用组件
import  'jquery'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import $ from "jquery";
window.$ = $;
import Vuex from 'vuex'
import i18n from '../src/lang/index'  // 引入配置好的文件
import cookie from 'vue-cookies'
window.$cookie=cookie//创建本地cookie
import store from "../src/utility/store"
import { RadioGroup, Radio,Button } from 'vant';
import { Field, CellGroup } from 'vant';
import gridLayout from 'vue-grid-layout'

var appT = createApp(PageOne)
let app = appT;

appT.use(store);
appT.use(i18n);

appT.use(Radio);
appT.use(RadioGroup);
appT.use(Button)
app.use(Field);
app.use(CellGroup);
app.use(gridLayout);


appT.config.globalProperties.$LoginedInfo = reactive({
    login:false,
    userName:''
});

window.$myFunction = function() {
    console.log('This is a global function.');
};


appT.mount('#app')

import BrowserLogger from '@arms/js-sdk'
const __bl = BrowserLogger.singleton(
    {
        pid:"feamcqah85@4922c2778dbe531",
        appType:"web",
        imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
        sendResource:true,
        enableLinkTrace:true,
        behavior:true
    }
);



